<template>
  <v-container>
    <v-row class="my-3 px-3">
    <h1>Forecast Vergleich <small>&ndash; {{ getColumnHeader(config.c1)}} mit {{ getColumnHeader(config.c2)}}</small></h1>
     <v-spacer></v-spacer>
    <ForecastTools class="mt-2" />
    </v-row>

     <v-progress-linear
      v-if="!initialized"
      indeterminate
      rounded
      color="primary"
    ></v-progress-linear>
    <div v-else>
    <v-row class="mt-4">
      <v-col cols="6">
          <v-card class="pa-6 elevation-1">
            <v-row class="d-flex justify-space-between">
              <v-btn icon :disabled="!nav.prev" @click="navigate(nav.prev)" style="flex: 1"><v-icon>mdi-chevron-left</v-icon></v-btn>
              <div class="timerange">
                <div class="eternity"></div>
                <div class="range" :style="timerange.style"></div>
              </div>
              <v-btn icon :disabled="!nav.next" @click="navigate(nav.next)" style="flex: 1"><v-icon>mdi-chevron-right</v-icon></v-btn>
            </v-row>
            <v-row>
              <v-col cols="6" class="pr-6">
              <v-select
                v-model="config.c1"
                :items="snapshots"
                item-disabled="value.disabled"
                outlined
                @change="fetchBudgets"
            >
             <template slot="selection" slot-scope="{ item }">
              <div class="d-flex justify-space-between" style="width:100%">
                <div>{{ item.text.str_fc }}&nbsp;&ndash;&nbsp;<strong>{{ item.text.str_snap }}</strong></div>
               <div style="color:#777" v-html="item.text.str_closed"></div>
              </div>
            </template>

             <template slot="item" slot-scope="{ item }">
              <div class="d-flex justify-space-between" style="width:100%">
                <div>{{ item.text.str_fc }}&nbsp;&ndash;&nbsp;<strong>{{ item.text.str_snap }}</strong></div>
               <div style="color:#777" v-html="item.text.str_closed"></div>
              </div>
            </template>

            </v-select>
           
            <div :class="{faded: loading}" class="text-center mb-4"><PieChart :data="data.c1" :localValues="false" :disableTooltip="true" label="A" /></div>
              <ul :class="{faded: loading}" class="widgettable">
                <li v-for="i in changes.c1.status" :key="i.value">
                  <div>
                     <v-icon size="14" :color="$store.state.budget.status[i.value].color">{{
                        $store.state.budget.status[i.value].icon
                      }}</v-icon> {{$store.state.budget.status[i.value].label }}
                  </div>
                  <span class="text-right" v-html="toLocale(i.percent) +'&thinsp;%'"></span>
                  <span class="text-right" v-html="toLocale(i.total)"></span>
                </li>
                <li class="total">
                  <div>Gesamt</div>
                  <span class="text-right" v-html="toLocale(100) +'&thinsp;%'"></span>
                  <span class="text-right" v-html="toLocale(changes.c1.total)"></span>
                  </li>
              </ul>
              </v-col>
              <v-col cols="6" class="pl-6">
                 <v-select
                  v-model="config.c2"
                  :items="snapshots"
                  item-disabled="value.disabled"
                  outlined
                  @change="fetchBudgets"
            >
             <template slot="selection" slot-scope="{ item }">
              <div class="d-flex justify-space-between" style="width:100%">
                <div>{{ item.text.str_fc }}&nbsp;&ndash;&nbsp;<strong>{{ item.text.str_snap }}</strong></div>
               <div style="color:#777">{{ item.text.str_closed }}</div>
              </div>
            </template>

             <template slot="item" slot-scope="{ item }">
              <div class="d-flex justify-space-between" style="width:100%">
                <div>{{ item.text.str_fc }}&nbsp;&ndash;&nbsp;<strong>{{ item.text.str_snap }}</strong></div>
               <div style="color:#777">{{ item.text.str_closed }}</div>
              </div>
            </template>
            
            </v-select>
            <div :class="{faded: loading}" class="text-center mb-4"><PieChart :data="data.c2" :localValues="false" :disableTooltip="true" label="A" /></div>
              <ul :class="{faded: loading}" class="widgettable">
                <li v-for="i in changes.c2.status" :key="i.value">
                  <div>
                     <v-icon size="14" :color="$store.state.budget.status[i.value].color">{{
                        $store.state.budget.status[2].icon
                      }}</v-icon> {{$store.state.budget.status[i.value].label }}
                  </div>
                  <span class="text-right" v-html="toLocale(i.percent) +'&thinsp;%'"></span>
                  <span class="text-right" v-html="toLocale(i.total)"></span>
                </li>
                <li class="total">
                  <div>Gesamt</div>
                  <span class="text-right" v-html="toLocale(100) +'&thinsp;%'"></span>
                  <span class="text-right" v-html="toLocale(changes.c2.total)"></span>
                  </li>
              </ul>
              </v-col>
            </v-row>
          </v-card>
      </v-col>
      <v-col cols="6">
          <v-card class="pa-6 elevation-1" :class="{faded: loading}"> 
                <v-tabs fixed-tabs v-model="tabs.tab">
                  <v-tabs-slider color="primary"></v-tabs-slider>
                    <v-tab
                      v-for="(item, index) in tabs.items"
                      :key="index"
                      @click="tabclick(index)"
                    >
                      {{ item }}
                    </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tabs.tab" class="mt-4">
                <v-tab-item>
  
                  <ul class="widgettable mt-2 mb-4">
                    <li v-for="s in status.slice().sort()" :key="s">
                      <div>
                          <v-icon size="12" :color="$store.state.budget.status[s].color">{{
                        $store.state.budget.status[s].icon
                      }}</v-icon>
                      Veränderung {{ $store.state.budget.status[s].label }}
                      </div>
                      <span class="right" v-html="coloredToLocale(changes.delta.status[s].total)"></span>
                    </li>
                    
                    <li class="total"><div>

                      <v-icon size="12" v-for="s in status.slice().sort()" :key="s" :color="$store.state.budget.status[s].color">{{
                        $store.state.budget.status[s].icon
                      }}</v-icon>
    
                      Veränderung Gesamt:</div>
                      <span class="right" v-html="coloredToLocale(changes.delta.total)"></span> 
                      </li>

                  </ul>
                  <ul class="widgettable">
                    <li class="clickable" @click="setClientFilter(changes.delta.clients.hi.data, 'Hi-Kunden')"><div>
                       <v-icon size="12" v-for="s in status.slice().sort()" :key="s" :color="$store.state.budget.status[s].color">{{
                        $store.state.budget.status[s].icon
                      }}</v-icon>
                      
                      Plus durch "Hi"-Kunden:</div> <span class="right" v-html="coloredToLocale(changes.delta.clients.hi.budget)"></span></li>
                    <li class="clickable" @click="setClientFilter(changes.delta.clients.bye.data, 'Good-Bye-Kunden')" ><div>
                       <v-icon size="12" v-for="s in status.slice().sort()" :key="s" :color="$store.state.budget.status[s].color">{{
                        $store.state.budget.status[s].icon
                      }}</v-icon>
                      Minus durch "Good-Bye"-Kunden:</div> <span class="right" v-html="coloredToLocale(changes.delta.clients.bye.budget * -1)"></span></li>
                    <li class="clickable" @click="setClientFilter(changes.delta.clients.stay.data, 'Bestandskunden')"><div>
                       <v-icon size="12" v-for="s in status.slice().sort()" :key="s" :color="$store.state.budget.status[s].color">{{
                        $store.state.budget.status[s].icon
                      }}</v-icon>
                      Veränderung Bestandskunden:</div> <span class="right" v-html="coloredToLocale(changes.delta.clients.stay.budget)"></span></li>
                  </ul>
                 
                </v-tab-item>
                
                <v-tab-item>
                  <p class="small">"Hi"-Kunden sind alle Kunden, die in {{ getColumnHeader(config.c1) }} nicht vorkamen, aber in {{ getColumnHeader(config.c2) }} Budget führen.</p>
                  <v-chip v-for="i in changes.delta.clients.hi.data" :key="i.id" @click="setClientFilter(i.id, 'Hi-Kunden', i.name)"  outlined color="green" class="ma-1">{{ i.name }}</v-chip>
      
                </v-tab-item>
                <v-tab-item>
                <p class="small">"Good-Bye"-Kunden sind alle Kunden, die in {{ getColumnHeader(config.c1)}} Budget führten, aber in {{ getColumnHeader(config.c2)}} nicht mehr enthalten sind.</p>
                <v-chip v-for="i in changes.delta.clients.bye.data" :key="i.id" :title="toLocale(i.budget * -1)" @click="setClientFilter(i.id, 'Good-Bye-Kunden', i.name)"  outlined color="red" class="ma-1">{{ i.name }}</v-chip>
               

              </v-tab-item>
              <v-tab-item>
                  <div v-if="changes.transfers.length">
                    <p class="small">Im betrachteten Zeitraum haben folgende Budget-Transfers stattgefunden:</p>
                    <ul class="widgettable pa-0 ma-0">
                    <li v-for="(t, index) in changes.transfers" :key="index">
                      <div>{{ d2s(t.transfer_date, "dateshort") }}</div>
                      <div>{{ t.from.name }} <i class="mdi mdi-transfer-right"></i>  {{ t.to.name }}</div>
                      <div v-html="strellipsis(getClientPath(t.client.client_id), 48)"></div>
                      <div class="text-right">{{ toLocale(t.sum) }}</div>
                    </li>
                    <li class="total d-none">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div class="right">{{ toLocale(changes.transfers.reduce((accumulator, current) => accumulator + current.sum, 0))}}</div>
                    </li>
                    </ul>
                    <v-checkbox class="d-none" dense label="Budget-Transfers berücksichtigen" v-model="includeTransfers"  @change="renderDataTable"></v-checkbox>
                    
                    </div>
                    <div v-else>
                      Keine Budget-Transfers gefunden.
                    </div>
                </v-tab-item>
            </v-tabs-items>
          </v-card>

      </v-col>
    </v-row>

    <v-row class="mt-4 mb-0">
    
      <v-col cols="3">
        <v-select
          v-model="by"
          :items="renderBy"
           label="kumuliert auf"
          @change="renderDataTable"
           outlined
          dense
        >
        </v-select>

      </v-col>
        <v-col cols="3">
        <v-select
          v-model="field"
          :items="$store.state.budget.fields.filter(f => f.sumup)"
          item-text="label"
          item-value="key"
           label="betrachtetes Budget"
          @change="renderDataTable"
           outlined
          dense
        >
        </v-select>
      </v-col>
      <v-col cols="3">
         <StatusSelector @change="statuschange" />
      </v-col>

      <v-col cols="3">
        <v-text-field 
          v-model="search.query"  
          prepend-inner-icon="mdi-magnify" 
          outlined 
          clearable 
          dense 
          :persistent-placeholder="true" 
          :label="search.label" 
          @click:clear="setClientFilter()">
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="mx-1">
    </v-row>
   
    <div>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :search="search.query"
        :items-per-page="25"
        :hide-default-footer="items.length <= 25"
        :sort-by="['delta']"
        :sort-desc="true"
        :must-sort="true"
        dense
        locale="de-DE"
        show-expand
        :single-expand="true"
        class="elevation-2"
        :class="{faded: loading}"
        @item-expanded="adjustColumns"
        no-data-text="Keine Daten gefunden."
        :footer-props="{
          itemsPerPageOptions: [10, 25, 50, -1],
          itemsPerPageText: 'Zeilen pro Seite',
        }"
      >
          <template v-slot:[`item.col_2`]="{ item }">
            <span v-html="item.col_2"></span>
          </template>


          <template v-slot:[`item.c1`]="{ item }">
            <span>{{ toLocale(item.c1) }}</span>
          </template>

          <template v-slot:[`item.c2`]="{ item }">
            <span>{{ toLocale(item.c2) }}</span>
          </template>

          <template v-slot:[`item.delta`]="{ item }">
            <span v-html="coloredToLocale(item.delta)"></span>
          </template>

          <template v-slot:[`item.percent`]="{ item }">
            <span v-html="coloredToLocale(item.percent)"></span>&nbsp;<span>%</span>
          </template>

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <v-simple-table>
              <tr v-for="(row, index) in item.thenBy" :key="index">
                <td class="colindex-0">{{ row.by }}</td>
                <td class="colindex-1" v-html="row.thenBy"></td>
                <td class="colindex-2 text-right">{{ toLocale(row.c1) }}</td>
                <td class="colindex-3 text-right">{{ toLocale(row.c2) }}</td>
                <td class="colindex-4 text-right"><span v-html="coloredToLocale(row.delta)"></span></td>
                <td class="colindex-5">
                  <Changelog
                    v-if="config.c1.fcid === config.c2.fcid"
                    :by="by"
                    :value="item.id"
                    :within="thenBy"
                    :withinValue="row.id"
                    :status="status"
                    :fcid="config.c2.fcid"
                    :field="field"
                    :first="config.c1.snapshot"
                    :last="config.c2.snapshot"
                    :useClientGroups="true"
                  >
                    <v-btn icon small style="opacity: 0.75"
                      ><v-icon small>mdi-timeline-text-outline</v-icon></v-btn
                    >
                  </Changelog>
                  <v-btn v-else icon small style="opacity: 0.66" @click="temporaryAlert()"
                      ><v-icon small>mdi-timeline-text-outline</v-icon></v-btn
                    >
                </td>
              </tr>

              </v-simple-table>
            </td>
          </template>

        <template v-slot:[`footer.page-text`]="items">
          {{ items.pageStart }} - {{ items.pageStop }} {{ trl("of") }}
          {{ items.itemsLength }}
        </template>

      </v-data-table>
      <p class="mt-4 mx-2" style="cursor:pointer" v-if="clientfilter.length > 0" @click="setClientFilter()"><v-icon size="14" color="green">mdi-filter</v-icon>&ndash;&nbsp;<strong>Kundenfilter aktiv!</strong> Alle Werte in der Tabelle beziehen sich auf die "{{ search.label }}"! Klicke hier, um den Kundenfilter zu entfernen. </p>
    </div>
    </div>
  </v-container>
</template>

<script>
import store from "@/store";
import { mapGetters } from "vuex";

import {
  trl,
  getDivisionName,
  getClientName,
  getAttributeName,
  smartjoin
} from "@/utils/strings";

import { toLocale, coloredToLocale } from "@/utils/numbers"
import { numerus, strellipsis } from "@/utils/strings"
import { getClientGroup, getClientPath } from "@/utils/clients";
import { d2s } from "@/utils/dates"
import PieChart from "@/components/PieChart";
import Changelog from "@/components/Changelog";
import StatusSelector from "@/components/StatusSelector";
import ForecastTools from "@/components/ForecastTools.vue";

import { DateTime } from "luxon";

export default {
  name: "Compare",
  data() {
    return {
      initialized: false,
      loading: true,
     
      search: {
        query: "",
        label: "Suchbegriff",
        placeholder: ""
      },
      placeholder: "",
      data: {c1: [], c2: []},
      timerange: {
          days: 0,
          start: 0,
          length: 0,
          style:""
      },
      clientfilter: [],
      divisionfilter: [],
      field: "total_wo_fk",
      by: "client_id",
      thenBy: "division_id",
      renderBy: [
        {text: 'Kunden', value: 'client_id'},
        {text: 'Units', value: 'division_id'}
      ],
      status: [1, 2],
      includeTransfers: true,
      hideEmpty: true,
      config: {
        c1: {},
        c2: {},
      },
      headers: [{
        text: "",
        value: "col_1",
        class: "primary white--text"
      },
      {
        text: "",
        value: "col_2",
        align: 'left',
        class: "primary white--text"
      },
      {
        text: "A",
        value: "c1",
        align: 'right',
        class: "primary white--text"
      },
      {
        text: "B",
        value: "c2",
        align: 'right',
        class: "text-right primary white--text"
      },
      {
        text: "Diff",
        value: "delta",
        align: 'right',
        class: "primary white--text"
      },
      {
        text: "",
        value: "data-table-expand",
        class: "primary white--text"
      }],
      tabs: {
        tab: 0,
        items: [
          "Budget", "Hi", "Good-Bye",  "Transfers"
        ],
        text: "Hello World"
      },
      items: [],
      changes: {},
      transfers: [],
    };
  },
  computed: {
    ...mapGetters(["divisions", "clients", "forecasts"]),
    snapshots(){
      let arr=[];
      this.forecasts.filter(f => f.vis).forEach(f => {
          f.snapshots.forEach(s => {
            arr.push({
              value:{
                tenant: f.tenant_id, 
                fcid: f.id,
                snapshot:s.id,
                closed: s.closed_at,
                disabled: !s.closed_at,
                date: {
                  year: f.fy,
                  month: new Date(s.due_date).getMonth(),
                  day: new Date(s.due_date).getDay(),
                }
              },
              text:{
                str_fc: f.title,
                str_snap: s.title,
                str_closed: s.closed_at ? d2s(s.closed_at, 'dateshort') : "noch nicht geschlossen"
              }
            })
          })

          // live inkludieren
          // if (f.active) {
          //   arr.push({
          //     value:{
          //       tenant: f.tenant_id, 
          //       fcid: f.id,
          //       closed: false,
          //       date:{
          //         year: f.fy,
          //         month: new Date().getMonth(),
          //         day: new Date().getDay()
          //       }
          //     },
          //     text: {
          //       str_fc: f.title,
          //       str_snap:"live",
          //       str_closed: '☉'
          //     }
          //   })
          // }
      })
      return arr;
    },
    nav(){
        let closedSnaps = this.snapshots.filter(s => s.value.closed)
        let index_c1 = closedSnaps.findIndex(s => s.value.snapshot === this.config.c1.snapshot);
        let index_c2 = closedSnaps.findIndex(s => s.value.snapshot === this.config.c2.snapshot);
        let diff = index_c2 - index_c1;

        let prev = index_c1 - diff > 0 ? [index_c1 - diff, index_c2 - diff] : false;
        let next = index_c2 + diff < closedSnaps.length ? [index_c1 + diff, index_c2 + diff] : false;

      return {
        allow: diff > 0,
        prev: prev,
        next: next
      }
    }
  },
  methods: {
    toLocale,
    coloredToLocale,
    numerus,
    strellipsis,
    d2s,
    trl,
    getClientPath,
    tabclick(i){
      switch(i) {
        case 1:
          this.setClientFilter(this.changes.delta.clients.hi.data, 'Hi-Kunden');
          break;
        case 2:
          this.setClientFilter(this.changes.delta.clients.bye.data, 'Good-Bye-Kunden');
          break;
        case 3:
          this.setClientFilter();
          
          // let transferClients = this.changes.transfers.map(t => t.client.client_id);
          // transferClients = [...new Set(transferClients.map(c => getClientGroup(c)))];
          // let transferDivisions = [...new Set(this.changes.transfers.map(t => t.from.division_id).concat(this.changes.transfers.map(t => t.to.division_id)))];


          // console.log(transferClients);
          // console.log(transferDivisions);

          // this.clientfilter = transferClients;
          // this.divisionfilter = transferDivisions;
          // this.renderDataTable();


          break;
        default:
          this.setClientFilter();
      }
    },
    temporaryAlert(){
       this.$store.commit("alert", {
          title: "Coming in 2023!",
          text: "<p>Du vergleichst gerade zwei verschiedene Forecast-Jahre.</p><p>Die Timeline-Darstellung steht aktuell nur zur Verfügung, wenn beide Zeitpunkte innerhalb desselben Forecast-Jahres liegen.</p><p>Wir arbeiten daran und bitten bis dahin um etwas Geduld.",
        });
    },
    getSnapshotIndex(cfg){
        return this.snapshots.findIndex(s => s.value.snapshot === cfg.snapshot)
    },
    navigate(args){
        this.config.c1 = this.snapshots.filter(s => s.value.closed)[args[0]].value;
        this.config.c2 = this.snapshots.filter(s => s.value.closed)[args[1]].value;
        this.fetchBudgets();
    },
    setTimerange(){
        let rangeStart = this.config.c1.closed ? DateTime.fromSQL(this.config.c1.closed) : DateTime.now();
        let rangeEnd = this.config.c2.closed ? DateTime.fromSQL(this.config.c2.closed) : DateTime.now();

        let left = 100 / this.timerange.days * rangeStart.diff(this.timerange.start, "days").values.days
        let width = 100 / this.timerange.days * Math.ceil(rangeEnd.diff(rangeStart, "days").values.days);

        this.timerange.style="left:" + left + "%;width:" + Math.max(0.8, width) + "%";
    },
    adjustColumns(args){
      if (args.value) {
         let firstRow = document.querySelector('tbody').querySelector('tr');
          let widths = [];
          firstRow.querySelectorAll('td').forEach(elem => widths.push(elem.offsetWidth));

          this.$nextTick(() => {
            for (let i = 0; i < widths.length; i++) {
              document.querySelectorAll('.colindex-' + i).forEach(elem => elem.width = widths[i] + "px");
            }
          })
      }
    },
    async fetchBudgets() {
      this.loading = true;

      if (!this.nav.allow) {
        this.$store.commit("toggleSnackbar", {
          show: true,
          text: "Der erste Zeitpunkt muss vor dem zweiten liegen!",
          timeout: 0,
        });
        return;
      }

      this.$store.commit("toggleSnackbar", {show: false});

      const data = await this.$store.dispatch("req", {
        route:
          "compare?snaps=" + this.config.c1.snapshot + ","+ this.config.c2.snapshot
        });


      const data_this = data["c1"];
      const data_that = data["c2"];


      const transfers = await this.$store.dispatch("req", {
        route:
          "transfers?tenant=" + this.$store.getters.current.id + "&snaps=" + this.config.c1.snapshot + ","+ this.config.c2.snapshot
      });
      
      this.transfers = transfers;


      // let params_this = this.config.c1;
      // delete params_this.closed;
      // delete params_this.disabled;
      // delete params_this.date;

      // let params_that = this.config.c2;
      // delete params_that.closed;
      // delete params_that.disabled;
      // delete params_that.date;

      // const data_this = await this.$store.dispatch("req", {
      //   route:
      //     "budget?" +
      //     Object.keys(params_this)
      //       .map((key) => key + "=" + params_this[key])
      //       .join("&"),
      // });

      // const data_that = await this.$store.dispatch("req", {
      //   route:
      //     "budget?" +
      //     Object.keys(params_that)
      //       .map((key) => key + "=" + params_that[key])
      //       .join("&"),
      // });

      // console.log(data_a.length, data_this.length);
      // console.log(data_b.length, data_that.length);

      this.setTimerange();

      // alle clients auf clientgroups umschreiben
      data_this.forEach(row => {
        row.budget.original_client_id = row.budget.client_id; //{...row.budget.client_id}
        row.budget.client_id = getClientGroup(row.budget.client_id)
       })
       
      data_that.forEach(row => {
        row.budget.original_client_id = row.budget.client_id; //{...row.budget.client_id}
        row.budget.client_id = getClientGroup(row.budget.client_id)
      })
      
      // placeholder projects (parent_id === 0) rausfiltern ??
      // this.data = {
      //   c1: data_this.filter(row => row.parent_id !== 0),  
      //   c2: data_that.filter(row => row.parent_id !== 0) 
      // };

      this.data = {
        c1: data_this,
        c2: data_that
      };

      this.tabs.tab = 0;
      this.headers[2].text = this.getColumnHeader(this.config.c1);
      this.headers[3].text = this.getColumnHeader(this.config.c2);

      this.renderDataTable();
      this.initialized = true;
    },
    statuschange(newStatus){
     
      this.status = newStatus;
      this.renderDataTable();
    },
    renderDataTable(){

      this.getChanges();

      if (this.by === "client_id") {
        this.thenBy = "division_id";
        this.compareClients();
      } else if (this.by === "division_id") {
        this.thenBy = "client_id";
        this.compareDivisions();
      }
      this.loading = false;

    },
    compareClients() {
      this.headers[0].text = trl('budget_numClients');
      this.headers[1].text = trl('budget_numDivisions');

      let v1 = this.data.c1.filter((row) => this.status.includes(row.budget.status)).map((row) => row.budget.client_id);
      let v2 = this.data.c2.filter((row) => this.status.includes(row.budget.status)).map((row) => row.budget.client_id);
      
      let values = (this.clientfilter.length === 0) ? [...new Set(v1.concat(v2))] : this.clientfilter;

      this.items = [];
     

      values.filter((v) => v).forEach((v) => {
      
        let strLabel = getClientName(v);

        let subset_c1 = this.data.c1.filter(row => row.budget.client_id === v && this.status.includes(row.budget.status));
        let subset_c2 = this.data.c2.filter(row => row.budget.client_id === v && this.status.includes(row.budget.status));


        if (this.divisionfilter.length > 0) {
          subset_c1 = subset_c1.filter(row => this.divisionfilter.includes(row.division_id));
          subset_c2 = subset_c2.filter(row => this.divisionfilter.includes(row.division_id));
        }

        let sum_c1 = this.getSum(subset_c1);
        let sum_c2 = this.getSum(subset_c2);

        if (this.hideEmpty && sum_c1 === 0 && sum_c2 === 0) return;

        let thenBys = [];
        thenBys = thenBys.concat(subset_c1.map(row => row.division_id))
        thenBys = thenBys.concat(subset_c2.map(row => row.division_id))
        thenBys = [...new Set(thenBys)];
        
        let thenBy = [];
        
        thenBys.forEach(el => {
          let thenby_sum_c1 = this.getSum(subset_c1.filter(x => x.division_id === el));
          let thenby_sum_c2 = this.getSum(subset_c2.filter(x => x.division_id === el));

          if (this.hideEmpty && thenby_sum_c1 === 0 && thenby_sum_c2 === 0) return;

          let clients_c1 = subset_c1.filter(x => x.division_id === el).map(x => x.budget.original_client_id);
          let clients_c2 = subset_c2.filter(x => x.division_id === el).map(x => x.budget.original_client_id);
          
          let originalClients = [...new Set(clients_c1.concat(clients_c2))];

          let diff = "";
          if (originalClients.length > 1 || originalClients[0] !== v) {
            originalClients = originalClients.map(x => getClientPath(x));
            diff = smartjoin(originalClients, trl('and'));
          }

          thenBy.push({
            by: diff,
            id: el,
            thenBy: getDivisionName(el),
            c1: thenby_sum_c1,
            c2: thenby_sum_c2,
            delta: thenby_sum_c2 - thenby_sum_c1
          })
        })

        thenBy.sort((a, b) => a.thenBy.localeCompare(b.thenBy));

        this.items.push({
          id: v,
          col_1: strLabel, 
          col_2: smartjoin(thenBy.map(x => x.thenBy), trl('and')),
          thenBy: thenBy,
          c1: sum_c1, 
          c2: sum_c2, 
          delta: (sum_c2 - sum_c1),
          percent: (100 - ( 100 / sum_c1 * sum_c2)) * (-1)
        })
      })
      
    },
    compareDivisions(){
      this.headers[0].text = trl('budget_numDivisions');
      this.headers[1].text = trl('budget_numClients');

      let v1 = this.data.c1.filter((row) => this.status.includes(row.budget.status)).map((row) => row.division_id);
      let v2 = this.data.c2.filter((row) => this.status.includes(row.budget.status)).map((row) => row.division_id);
      
      let values = (this.divisionfilter.length === 0) ? [...new Set(v1.concat(v2))] : this.divisionfilter;
     
      this.items = [];
     
      values.filter((v) => v).forEach((v) => {

        let strLabel = getDivisionName(v);
        let subset_c1, subset_c2; 

        subset_c1 = this.data.c1.filter(row => row.division_id === v && this.status.includes(row.budget.status));
        if (this.includeTransfers) {
          subset_c2 = this.data.c2.filter(row => row.division_id === v && this.status.includes(row.budget.status));
        } else {
          //subset_c1 = this.data.c1.filter(row => (this.isTransfer(row) ? row.parent.division : row.division) === v && this.status.includes(row.budget.status));
          //subset_c2 = this.data.c2.filter(row => (this.isTransfer(row) ? row.parent.division : row.division) === v && this.status.includes(row.budget.status));         
          subset_c2 = this.data.c2.filter(row => (this.isTransfer(row) ? row.parent.division === v : row.division === v) && this.status.includes(row.budget.status));         
        }

        if (this.clientfilter.length > 0) {
          subset_c1 = subset_c1.filter(row => this.clientfilter.includes(row.budget.client_id));
          subset_c2 = subset_c2.filter(row => this.clientfilter.includes(row.budget.client_id));
        }

        let sum_c1 = this.getSum(subset_c1);
        let sum_c2 = this.getSum(subset_c2);

        if (this.hideEmpty && sum_c1 === 0 && sum_c2 === 0) return;


        let thenBys = [];
        thenBys = thenBys.concat(subset_c1.map(row => row.budget.client_id));
        thenBys = thenBys.concat(subset_c2.map(row => row.budget.client_id));
        thenBys = [...new Set(thenBys)];

        // if (this.clientfilter.length > 0) {
        //   thenBys = this.clientfilter;
        // }

        let thenBy = [];

        thenBys.forEach(el => {
          let thenby_sum_c1 = this.getSum(subset_c1.filter(x => x.budget.client_id === el));
          let thenby_sum_c2 = this.getSum(subset_c2.filter(x => x.budget.client_id === el));

          if (this.hideEmpty && thenby_sum_c1 === 0 && thenby_sum_c2 === 0) return;

          let clients_c1 = subset_c1.filter(x => x.budget.client_id === el).map(x => x.budget.original_client_id);
          let clients_c2 = subset_c2.filter(x => x.budget.client_id === el).map(x => x.budget.original_client_id);
          
          let originalClients = [...new Set(clients_c1.concat(clients_c2))];
              originalClients = originalClients.map(x => getClientPath(x));

          thenBy.push({
            by: "", // Gruppenname in der ersten Spalte
            id: el,
            thenBy: smartjoin(originalClients, trl('and')), // original bebuchte CLients in der rechten Spalte
            c1: thenby_sum_c1,
            c2: thenby_sum_c2,
            delta: thenby_sum_c2 - thenby_sum_c1
          })
        })

        thenBy.sort((a, b) => a.thenBy.localeCompare(b.thenBy))

        this.items.push({
          id: v,
          col_1: strLabel, 
          col_2: numerus('? Kunde|n', thenBy.length) + '<span class="d-none">' + smartjoin(thenBy.map(x => x.thenBy), trl('and')) + "</span>",
          thenBy: thenBy,
          c1: sum_c1, 
          c2: sum_c2, 
          delta: (sum_c2 - sum_c1),
          percent: (100 - ( 100 / sum_c1 * sum_c2)) * (-1)
        })

      })

    },
    setClientFilter(arg, strLabel, strValue){
      this.divisionfilter = [];
      if (!arg) {
        this.clientfilter = [];
        this.search.query = "";
        this.search.label = "Suchbegriff";
        this.renderDataTable();
        return;
      }

     
      let ids = (typeof arg === "object") ? arg.map(x => x.id) : [arg];

      if (ids.length === 0) ids = [-1];

      if (JSON.stringify(this.clientfilter) === JSON.stringify(ids)) {
        this.clientfilter = [];
        this.search.query = "";
        this.search.label = "Suchbegriff";
      } else {
        this.clientfilter = ids;
        this.search.query = strValue || " "; // force the clear icon to show
        this.search.label= strLabel
      }
      
      this.renderDataTable();
    },
    getSum(arr){
        return arr.reduce(
          (accumulator, current) => accumulator + current.budget[this.field],
          0
        )
    },
    isTransfer(project){
      if (!project.parent) return false;
      if (project.parent.division === project.division_id) return false;
      return true;
    },
    getTransfers(){
        let transfers = [];
        
        this.transfers
                .filter(t => DateTime.fromSQL(t.transfer_date) > DateTime.fromSQL(this.config.c1.closed) && DateTime.fromSQL(t.transfer_date) < DateTime.fromSQL(this.config.c2.closed))
                .forEach(t => {
                  let sum = t.budget.filter(b => this.status.includes(b.status)).reduce((acc, cur) => acc + cur[this.field], 0);
                  if (sum === 0) return;
                  t.sum = sum;
                  transfers.push(t);
                });

        this.tabs.items[3] = "Transfers (" + transfers.length + ")"
        return transfers;
    },
    getColumnHeader(cfg) {
      let yr = this.forecasts.find(f => f.id === cfg.fcid).fy.toString().substr(-2);
      if (!cfg.snapshot) return `'${yr} live`;
     
      return this.forecasts.find(f => f.id === cfg.fcid)?.snapshots.find(s => s.id === cfg.snapshot).title + '/' +yr;
    },
    getChanges(){
      let changes = {c1:{}, c2: {}, delta: {}, transfers: this.getTransfers()};
      let c1 = this.data.c1;
      let c2 = this.data.c2;

      // status
      let s0c1 = c1.filter(x => x.budget.status === 0)
      let s1c1 = c1.filter(x => x.budget.status === 1)
      let s2c1 = c1.filter(x => x.budget.status === 2)

      let s0c2 = c2.filter(x => x.budget.status === 0)
      let s1c2 = c2.filter(x => x.budget.status === 1)
      let s2c2 = c2.filter(x => x.budget.status === 2)
      
      changes.c1.status = [
        {value: 0, total: this.getSum(s0c1), percent: (100 / this.getSum(c1) * this.getSum(s0c1))},
        {value: 1, total: this.getSum(s1c1), percent: (100 / this.getSum(c1) * this.getSum(s1c1))},
        {value: 2, total: this.getSum(s2c1), percent: (100 / this.getSum(c1) * this.getSum(s2c1))},
      ]

      changes.c1.total = this.getSum(c1);
      changes.c2.total = this.getSum(c2);
      //changes.delta.total = changes.c2.total - changes.c1.total;

      changes.c2.status = [
        {value: 0, total: this.getSum(s0c2), percent: (100 / this.getSum(c2) * this.getSum(s0c2))},
        {value: 1, total: this.getSum(s1c2), percent: (100 / this.getSum(c2) * this.getSum(s1c2))},
        {value: 2, total: this.getSum(s2c2), percent: (100 / this.getSum(c2) * this.getSum(s2c2))}
      ]

      changes.delta.status = [
        {value: 0, total: changes.c2.status[0].total - changes.c1.status[0].total, percent: (100 / changes.c1.status[0].total * changes.c2.status[0].total)},
        {value: 1, total: changes.c2.status[1].total - changes.c1.status[1].total, percent: (100 / changes.c1.status[1].total * changes.c2.status[1].total)},
        {value: 2, total: changes.c2.status[2].total - changes.c1.status[2].total, percent: (100 / changes.c1.status[2].total * changes.c2.status[2].total)}
      ]

      changes.delta.total = 0;
      this.status.forEach(s => {
        changes.delta.total+= changes.delta.status.find(ss => ss.value === s).total
      });

      c1 = c1.filter(x => this.status.includes(x.budget.status));
      c2 = c2.filter(x => this.status.includes(x.budget.status));

      // Hi und Bye - Kunden
      let clients_c1 = [...new Set(c1.map(x => x.budget.client_id))];
      let clients_c2 = [...new Set(c2.map(x => x.budget.client_id))];
     

      let clients_hi = [];
      clients_c2.filter(x => !clients_c1.includes(x)).forEach(c => {

        let b = this.getSum(c2.filter(proj => proj.budget.client_id === c));

        if (this.hideEmpty && b === 0) return;

        clients_hi.push({
          id: c,
          name: getClientName(c),
          budget: b
        })
      }) 
      clients_hi.sort((a,b) => b.budget - a.budget);

      let clients_bye = [];
      clients_c1.filter(x => !clients_c2.includes(x)).forEach(c => {

        let b = this.getSum(c1.filter(proj => proj.budget.client_id === c));

        if (this.hideEmpty && b === 0) return;

        clients_bye.push({
          id: c,
          name: getClientName(c),
          budget: b
        })
      })
      clients_bye.sort((a,b) => b.budget - a.budget);

      let clients_stay = [];
      clients_c1.filter(x => clients_c2.includes(x)).forEach(c => {

        let b = this.getSum(c1.filter(proj => proj.budget.client_id === c));

        clients_stay.push({
          id: c,
          name: "", // brauchen wir nirgendwo
          budget: b
        })
      });

      changes.delta.clients = {
        hi: {
          data: clients_hi,
          budget: clients_hi.reduce((accumulator, current) => accumulator + current.budget, 0)
        },
        bye: {
          data: clients_bye,
          budget: clients_bye.reduce((accumulator, current) => accumulator + current.budget, 0)
        }
      }

      changes.delta.clients.stay = {
        data: clients_stay,
        budget: this.getSum(c2)  - this.getSum(c1) + changes.delta.clients.bye.budget - changes.delta.clients.hi.budget
      }

      this.tabs.items[1] = "Hi (" + clients_hi.length + ")"
      this.tabs.items[2] = "Good-Bye (" + clients_bye.length + ")"

      this.changes = changes;
    }
  },
  components: { PieChart, Changelog, ForecastTools, StatusSelector },
  created() {

    if (this.snapshots.filter(s => s.value.closed).length < 2) {
      this.$store.commit("alert", {title:"Forecast Vergleich nicht möglich", text:"Ein Vergleich ist erst möglich, wenn mindestens zwei geschlossene Snapshots existieren."});
      return;
    }

    this.config.c1 = this.snapshots.filter(s => s.value.closed).at(-2).value;
    this.config.c2 = this.snapshots.filter(s => s.value.closed).at(-1).value;
   
    let timerangeStart = DateTime.fromSQL(this.snapshots[0].value.closed);
    let timerangeEnd = DateTime.fromSQL(this.snapshots.filter(s => s.value.closed).at(-1).value.closed); //DateTime.now();

    this.timerange.days = Math.ceil(timerangeEnd.diff(timerangeStart, "days").values.days);
    this.timerange.start = timerangeStart;

    this.fetchBudgets()
  },
  beforeRouteEnter(to, from, next) {
    if (store.getters.current.permissions.FC_TENANT_READ) {
      store.state.ui.hideForecastSelector = true;
      next();
    } else {
      next({ name: "NotFound" });
    }
  },
   beforeRouteLeave(to, from, next) {
    store.state.ui.hideForecastSelector = false;
    next();
  }
};
</script>

<style lang="scss" scoped>

::v-deep .theme--light.v-data-table .v-data-table-header th.sortable.active .v-data-table-header__icon{
  color: rgba(255,255,255,87);
}

h2{
  display: block;
  text-align: center;
  margin-top:0;
  margin-bottom:1rem;
}

.widgettable{
  display: table;
  width:100%;
  font-size:90%;
  margin:0;
  padding:0;

  li{
    display: table-row;
   
    &.clickable{
      cursor: pointer;
    }

    & > div, & > span{
      display: table-cell;
       border-bottom: 1px solid #ccc;
       padding:0.25em 3em 0.25em 0em;

      &.right{
        text-align: right;
      }
    }

    

    &.total{
      font-weight: bold;
      & > div, & > span{
        border-bottom:1px double #ccc;
      
    }

    }

  }

}

::v-deep .pie{
  width:64px;
  height: 64px;
  margin: auto;
}

::v-deep .v-data-table__expanded__content{
  background-color: rgba(0,0,0,.05);
  // box-shadow: none !important;
  box-shadow: inset 0px 4px 8px -5px rgb(50 50 50 / 25%), inset 0px -4px 8px -5px rgb(50 50 50 / 25%) !important;

  &>td{
    padding:0 !important;
  }

  .v-data-table{
    background-color: transparent;
    opacity:.75;
    margin:.5rem 0;
    padding:0;

    tr:hover{
      background-color: rgba(0,0,0,.05);

    }

    tr td {
      padding:.2em 16px;
      border-bottom: 1px solid rgba(0,0,0,.15);
    }

    tr:last-child td{
      border-bottom: none;
    }

  }

  .chips {
    line-height: 1.5;
  }


}

::v-deep .v-tabs-items {
  min-height:272px;
}

.faded{
  opacity:0.5;
  transition-duration: .3s;
}

.timerange{
  display:block;
  width: 760px;
  position:relative;
 
  .eternity{
    height:6px;
    position:absolute;
    left:0;
    top:15px;
    background-color: rgba(0,0,0,0.1);
    width:100%;
    border-radius:12px;
  }

  .range{
    height:6px;
    position:absolute;
    top:15px;
    border-radius:12px;
    background-color: rgba(0,0,0,0.5);
  }

}
</style>